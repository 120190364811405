.card-style {
   height: 60vh;
    -webkit-box-shadow: 12px 12px 13px -14px rgba(82, 79, 76, 0.86); 
    box-shadow: 12px 12px 13px -14px rgba(75, 41, 2, 0.86);
    border-bottom: 5px solid #edc3c8!important;


}

.sm-text{
    font-size: 2.5vh;
}

.brake-line {
    height: 1px;
    border-bottom: 1px solid #d6d6d6;
}

@media screen and (max-width: 460px) {
    .card-style {
        height: 60vh;
         -webkit-box-shadow: 12px 12px 13px -16px rgba(82, 79, 76, 0.86); 
         box-shadow: 12px 12px 13px -16px rgba(75, 41, 2, 0.86);
         border-bottom: 5px solid #edc3c8!important;
     
     
     }
}
