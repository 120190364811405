.main-categories-div{
    display: flex;
    flex-wrap: wrap;
    padding: 0!important;
}

.flex-backg{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 50vh;
    width: 50vw;
}

.cat-heading{
    top: 70%;
    left: 10%;
    position: absolute;
}
.cat-heading h1{
    font-size: 6vh;
    color: #5d4141;
}
.cat-outer-box{
    flex-basis: 50%;
    height: 50vh;
    position: relative;
}

#healt-outer-box{
    flex-basis: 100%;
}
.cat-box{
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(2px);
}

#edu-box{
    background-image: url('../../img//categories//edu.png');
}

#birth-box{
    background-image: url('../../img/categories/birth.png');
    background-position: bottom!important;
}

#shopp-box{
    background-image: url('../../img//categories/shopp.png');
    background-position: top!important;
}
#sport-box{
    background-image: url('../../img//categories/sport.png');
    background-position: top!important;
}

#health-box{
    background-image: url('../../img//categories/health.png');
    background-position: bottom!important;
}

#school-box{
    background-image: url('../../img//categories/school.png');
    background-position: bottom!important;
    filter: contrast(0.7) blur(2px);

}

#kinder-box{
    background-image: url('../../img//categories/kinder.png');
    background-position: bottom!important;
}

#lang-box{
    background-image: url('../../img//categories/lang.png');
}

#art-box{
    background-image: url('../../img//categories/art.png');
    background-position: center!important;
}

#pris-box{
    background-image: url('../../img//categories/pris.png');
    background-position: bottom!important;
    filter: contrast(0.7) blur(2px);

}

#prif-box{
    background-image: url('../../img//categories/prif.png');
    background-position: bottom!important;
    filter: contrast(0.7) blur(2px);


}

#food-box {
    background-image: url('../../img//categories/food.png');
}

#photo-box{
    background-image: url('../../img//categories/photo.png');
    filter: contrast(0.7) blur(2px);

}

#music-box{
    background-image: url('../../img//categories/music.png');
    background-position: bottom!important;
    filter: contrast(0.7) blur(2px);

}
#sweet-box{
    background-image: url('../../img//categories/sweet.png');
}

#place-box{
    background-image: url('../../img//categories/place.png');
    filter: contrast(0.5) blur(2px);
    
}

#toys-box{
    background-image: url('../../img//categories/toys.png');
    background-position: top!important;
    filter: contrast(0.8) blur(2px);

}

#baby-box {
    background-image: url('../../img//categories/baby.png');
}

#clo-box {
    background-image: url('../../img//categories/clo.png');
}

#pans-box {
    background-image: url('../../img//categories/pans.png');
}

#medi-box {
    background-image: url('../../img//categories/medi.png');
    filter: contrast(0.7) blur(2px);
}

#private-box {
    background-image: url('../../img//categories/private.png');
}

#state-box {
    background-image: url('../../img//categories/state.png');
    background-position: bottom!important;

}

#sport-school-box {
    background-image: url('../../img//categories/sportSchool.png');
    filter: contrast(0.5) blur(2px);

}

#pools-box {
    background-image: url('../../img//categories/pools.png');
}

#prif-box + .cat-heading,
#pris-box + .cat-heading{
    top: 50%;
}

.sl-bubble5{
    margin-top: 30px!important;
}

@media screen and (max-width: 460px) {
    .main-categories-div{
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .cat-outer-box{
        width: 100%;
        height: 30vh;
        flex-basis: auto!important;
    }
    .cat-heading h1{
        font-size: 4vh;
    }
}
