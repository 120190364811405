

.mainModal .modal-content{
    background: transparent;
    border: 0px;
}

.mapInput{
    width: 30vw!important;
    height: 5vh;
    border: 2px solid #0063f2!important;
}
.white{
    color: #fff;
}


.modal-backdrop.show{
    opacity: 0.8!important;
}

.serbiaMap{
    height: 70vh;
    background-image: url('../../../img/serbia_map.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.image {
    height: 5vh;
}