.homeBanner {
    background-image: url('https://static.tehnomanija.rs/UserFiles/banners/14_33898.webp');
    height: 25vh;
    background-size: cover;
    background-position: center;
}



@media screen and (max-width: 460px) {
    .homeBanner {
        background-image: url('https://static.tehnomanija.rs/UserFiles/banners/14_33898.webp');
        height: 20vh;
        background-size: cover;
        background-position: center;
    }
    
}